<template>
  <article class="bill">
    <img class="pro" src="../../../assets/logo-pro.png" />

    <h2>Facturation</h2>

    <h3>Mesure: {{ bill.service.name }} [{{ bill.service.code }}]</h3>

    <h3>
      Assuré·e:

      {{ bill.getFullname() }}
    </h3>

    <table v-for="taskBag in bill.getSortedTasks()">
      <tr>
        <th colspan="2">{{ taskBag.task.name }}</th>
      </tr>
      <tr v-for="activity in taskBag.getSortedActivities()">
        <td>{{ (new Date(activity.date)).toLocaleDateString() }}</td>
        <td>{{ minutesToHoursMinutes(activity.minutes) }}</td>
      </tr>
    </table>

    <table>
      <tr>
        <th>Total heures</th>
        <td>{{ minutesToHoursMinutes(bill.totalMinutes) }}</td>
      </tr>
      <tr>
        <th>Total en francs</th>
        <td>{{ (bill.totalMinutes * 190 / 60).toFixed(2) }}</td>
      </tr>
    </table>
  </article>
</template>

<script>
  import { minutesToHoursMinutes } from '../../../utils'
  import Tasks from '../pages/Tasks'

  export default {
    name: 'Bill',
    components: { Tasks },
    props: [
      'bill',
    ],

    methods: {
      minutesToHoursMinutes,
    },
  }
</script>

<style scoped>
h2, h3, th, td {
  text-align: center;
  border: solid 2px black;
  padding: 0.5em;
}

h2, h3, th {
  background-color: #D9D9D9;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

h3, th, td {
  font-weight: bold;
  font-size: 1em;
}

article > *:not(:first-child) {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th + td, td {
  width: 50%;
}

img.pro {
  display: none;
}

@media print {
  article {
    font-size: 12px;
  }

  img.pro {
    display: block;
    height: 1.5cm;
  }
}
</style>
