<template>
  <v-container
    id="billing"
    fluid
    tag="section"
    css framework
  >

    <base-material-card
      class="px-5 py-3 no-print"
    >
      <template
        v-slot:heading
      >
        <div
          class="d-flex flex-wrap align-center"
          style="margin: -15px 0"
        >
          <div class="text-h4 font-weight-light">
            Facturation
          </div>
          <v-spacer/>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="humanizedDates"
                label="Période"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              scrollable
              first-day-of-week="1"
              show-week
              show-current
              locale="fr-fr"
              color="green lighten-1"
              :max="todayISO"
              range
              @change="$refs.menu.save(dates)"
            />
          </v-menu>
        </div>
      </template>
    </base-material-card>
    <Bill v-for="(customerBag, key) in activitiesByCustomerService" :bill="customerBag" :key="key"></Bill>
  </v-container>
</template>

<script>
  import gql from 'graphql-tag'

  import Bill from '../components/Bill'

  import { minutesToHoursMinutes, toDateISO } from '../../../utils'
  import { startOfMonth, startOfWeek } from 'date-fns'

  class BillingBag {
    constructor (customer, service) {
      this.customer = customer
      this.service = service
      this.totalMinutes = 0
      this.taskBags = {}
    }

    getFullname () {
      return this.customer.last_name.toUpperCase() + ' ' + this.customer.first_name
    }

    addActivity (activity) {
      if (!(activity.task.id in this.taskBags)) {
        this.taskBags[activity.task.id] = new TaskBag(activity.task)
      }

      this.taskBags[activity.task.id].addActivity(activity)
      this.totalMinutes += activity.minutes
    }

    getSortedTasks () {
      const tasks = Object.values(this.taskBags)
      tasks.sort((a, b) => a.task.name.localeCompare(b.task.name))
      return tasks
    }
  }

  class TaskBag {
    constructor (task) {
      this.task = task
      this.activities = []
    }

    addActivity (activity) {
      this.activities.push(activity)
    }

    getSortedActivities () {
      const activities = [...this.activities]
      activities.sort((a, b) => a.date.localeCompare(b.date))
      return activities
    }
  }

  export default {
    name: 'Billing',

    components: {
      Bill,
    },

    data () {
      const today = new Date()
      const start = toDateISO(startOfMonth(today))

      return {
        today,
        dates: [start, toDateISO(today)],
        menu: false,
        activities: [],
      }
    },

    computed: {
      start () {
        return this.dates[0]
      },
      end () {
        return this.dates[this.dates.length - 1]
      },
      humanizedDates () {
        if (this.dates[0] !== this.dates[1]) {
          return (new Date(this.dates[0])).toLocaleDateString('fr-CH', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          }) + ' au ' + (new Date(this.dates[1])).toLocaleDateString('fr-CH', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          })
        } else {
          return (new Date(this.dates[0])).toLocaleDateString('fr-CH', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          })
        }
      },
      todayISO () {
        return toDateISO(this.today)
      },
      activitiesByCustomerService () {
        const billingBags = {}

        for (const activity of this.activities) {
          if (!activity.customer || !activity.task || !activity.service || !activity.service.code) {
            continue
          }

          const customerId = activity.customer.id
          const serviceId = activity.service.id
          const key = [customerId, serviceId]

          if (!(key in billingBags)) {
            billingBags[key] = new BillingBag(activity.customer, activity.service)
          }

          billingBags[key].addActivity(activity)
        }

        const sortedBags = Object.values(billingBags)
        sortedBags.sort((a, b) => a.getFullname().localeCompare(b.getFullname()))
        return sortedBags
      },
    },

    methods: {
      minutesToHoursMinutes,
    },

    watch: {
      dates () {
        this.$nextTick(() => this.$apollo.queries.activities.refetch(
          {
            startDate: this.start,
            endDate: this.end,
          },
        ))
      },
    },

    apollo: {
      activities () {
        return {
          query: gql`
          query  ($startDate: String!, $endDate: String!) {
              activities  (filter: {_and: [{date: { _gte: $startDate } }, {date: { _lte:  $endDate } }]}, limit: -1) {
                date
                minutes

                customer {
                  id
                  first_name
                  last_name
                  avs_number
                }

                task (filter: {chargeable: { _eq: true}}) {
                  id
                  name
                  chargeable
                }

                service {
                  id
                  name
                  code
                }
              }
          }
          `,
          variables: {
            startDate: this.start,
            endDate: this.end,
          },
          error (error) {
            const code = error.networkError?.statusCode
            if (code === 400 || code === 401) {
              localStorage.removeItem('jwt')
              this.$router.push({ name: 'Login' })
            }
          },
        }
      },
    },
  }
</script>

<style>
article:not(:last-child) {
  margin-bottom: 200px;
}

@media print {
  article:not(:last-child) {
    page-break-after: always;
  }
}
</style>
